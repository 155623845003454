// actions/actions.js
export const setClosed = (value) => {
  return {
    type: 'SET_CLOSED',
    payload: value,
  };
};

// Action creator for setting showError state
export const setShowError = (value) => {
  return {
    type: 'SET_SHOW_ERROR',
    payload: value,
  };
};

// Action creator for setting showNotification state
export const setShowNotification = (value) => {
  return {
    type: 'SET_SHOW_NOTIFICATION',
    payload: value,
  };
};
