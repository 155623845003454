import Layout from "./Layout";
import Animation from './Animation'
import Bar from "./bar";
import Buttons from "./Buttons";

function Coming() {
  return (
    <Layout>
        <Animation />
        <Bar />
        <Buttons />
    </Layout>
  );
}

export default Coming;