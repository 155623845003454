import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import computer from '../../images/computer.svg';
import files from '../../images/files.svg';
import bin from '../../images/bin.svg';
import file from '../../images/file.svg';
import './icons.css';
import { setShowError } from '../../redux/actions/actions';

function Icons({ setShowError, showError }) {
  const binRight = window.innerWidth - 160;
  const inBottom = window.innerHeight - 120;


  const random = () => {
    return Math.floor(Math.random() * (100 - 10 + 1)) + 10;
  };

  const handleUselessClicks = () => {
    setShowError({x: random(), y: random()});
  }

  useEffect(() => {
    console.log('Updated showError:', showError);
  }, [showError]);

  return (
    <div className="icons">
      <Draggable
        defaultPosition={{ x: 30, y: 30 }}
        position={null}
        grid={[20, 20]}
        scale={1}
      >
        <button onDoubleClick={handleUselessClicks} className='icons-drag-area'>
          <img className='icons-drag' src={computer} alt="My Computer" />
          <span>My Computer</span>
        </button>
      </Draggable>
      <Draggable
        defaultPosition={{ x: 30, y: 120 }}
        position={null}
        grid={[20, 20]}
        scale={1}
      >
        <button onDoubleClick={handleUselessClicks} className='icons-drag-area'>
          <img className='icons-drag' src={files} alt="My Nudes" />
          <span>My Nudes</span>
        </button>
      </Draggable>
      <Draggable
        defaultPosition={{ x: binRight, y: inBottom }}
        position={null}
        grid={[20, 20]}
        scale={1}
      >
        <button onDoubleClick={handleUselessClicks} style={{ width: 60 }} className='icons-drag-area'>
          <img className='icons-drag' src={bin} alt="My Life" />
          <span>My Life</span>
        </button>
      </Draggable>
      <Draggable
        defaultPosition={{ x: binRight, y: 120 }}
        position={null}
        grid={[20, 20]}
        scale={1}
      >
        <button onDoubleClick={handleUselessClicks} style={{ width: 45 }} className='icons-drag-area'>
          <img className='icons-drag' src={file} alt="My Life" />
          <span>6_must_never_show_anyone.mov</span>
        </button>
      </Draggable>
    </div>
  );
}

const mapStateToProps = state => ({
  showError: state.showError, // Updated mapStateToProps
});

const mapDispatchToProps = {
  setShowError,
};

export default connect(mapStateToProps, mapDispatchToProps)(Icons); // Connect the component to the Redux store
