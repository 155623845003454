import Layout from "./Layout";
import { setShowError } from '../../redux/actions/actions';
import { connect } from 'react-redux';

const errorIcon = <svg width="60" height="60" viewBox="0 0 162 162" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M158.045 80.9426C158.045 123.343 123.672 157.716 81.2588 157.716C38.8588 157.716 4.48535 123.343 4.48535 80.9426C4.48535 38.5293 38.8588 4.15591 81.2588 4.15591C123.672 4.15591 158.045 38.5293 158.045 80.9426Z" fill="#F98878"/>
<path d="M81.2588 8.0231C41.0588 8.0231 8.35198 40.7298 8.35198 80.9432C8.35198 121.143 41.0588 153.85 81.2588 153.85C121.459 153.85 154.179 121.143 154.179 80.9432C154.179 40.7298 121.459 8.0231 81.2588 8.0231ZM81.2588 161.583C36.7921 161.583 0.618652 125.41 0.618652 80.9432C0.618652 36.4632 36.7921 0.289764 81.2588 0.289764C125.739 0.289764 161.912 36.4632 161.912 80.9432C161.912 125.41 125.739 161.583 81.2588 161.583Z" fill="#512A07"/>
<path d="M96.1389 80.943L122.206 54.8764C126.312 50.7563 126.312 44.1031 122.206 39.9964C118.086 35.8897 111.432 35.8897 107.326 39.9964L81.2589 66.0631L55.1923 39.9964C51.0856 35.8897 44.4323 35.8897 40.3256 39.9964C36.2189 44.1031 36.2189 50.7563 40.3256 54.8764L66.3923 80.943L40.3256 106.996C36.2189 111.116 36.2189 117.77 40.3256 121.876C42.3789 123.93 45.0722 124.956 47.7522 124.956C50.4455 124.956 53.1389 123.93 55.1923 121.876L81.2589 95.8097L107.326 121.876C109.379 123.93 112.072 124.956 114.765 124.956C117.459 124.956 120.139 123.93 122.206 121.876C126.312 117.77 126.312 111.116 122.206 106.996L96.1389 80.943Z" fill="#FFF4DC"/>
<path d="M81.2588 91.9426C82.2855 91.9426 83.2721 92.3426 83.9921 93.0759L110.059 119.143C112.579 121.649 116.952 121.649 119.472 119.143C122.059 116.556 122.059 112.329 119.459 109.729L93.4054 83.6759C92.6721 82.9426 92.2721 81.9559 92.2721 80.9426C92.2721 79.9159 92.6721 78.9292 93.4054 78.2092L119.472 52.1426C122.059 49.5426 122.059 45.3159 119.459 42.7293C116.872 40.1293 112.645 40.1426 110.059 42.7293L83.9921 68.796C82.5521 70.2493 79.9788 70.2493 78.5255 68.796L52.4588 42.7293C49.8588 40.1293 45.6455 40.1426 43.0588 42.7293C40.4721 45.3159 40.4721 49.5426 43.0588 52.1426L69.1255 78.2092C69.8455 78.9292 70.2588 79.9159 70.2588 80.9426C70.2588 81.9559 69.8455 82.9426 69.1255 83.6759L43.0588 109.729C40.4588 112.329 40.4588 116.556 43.0588 119.143C45.5788 121.649 49.9388 121.649 52.4588 119.143L78.5255 93.0759C79.2455 92.3426 80.2321 91.9426 81.2588 91.9426ZM114.765 128.823C110.925 128.823 107.312 127.329 104.592 124.609L81.2588 101.276L57.9255 124.609C52.4988 130.049 43.0321 130.049 37.5921 124.609C31.9788 119.009 31.9786 109.876 37.5786 104.263L60.9255 80.9426L37.5921 57.6092C31.9788 51.9959 31.9786 42.876 37.5786 37.2626C43.1919 31.6626 52.3121 31.6493 57.9255 37.2626L81.2588 60.596L104.592 37.2626C110.192 31.6626 119.325 31.6493 124.939 37.2626C130.539 42.876 130.539 51.9959 124.939 57.6092L101.605 80.9426L124.939 104.263C130.539 109.876 130.539 119.009 124.939 124.609C122.219 127.329 118.605 128.823 114.765 128.823Z" fill="#512A07"/>
</svg>


function Error({setShowError, showError}) {

    const random = () => {
        return Math.floor(Math.random() * (100 - 10 + 1)) + 10;
      };
    
    const handleUselessClicks = () => {
        setShowError({x: random(), y: random()});
      }
    

  return (
    <Layout>
        <>
            <div className="error-top-wrapper">
                {errorIcon}
                <div>
                    <span>You have got overload error</span>
                    <p>Looks like you have got enough of this sh!t, dude?</p>
                </div>
            </div>

            <div className="error-buttons-wrapper">
                <button onClick={handleUselessClicks} className="button-nope">Nope</button>
                <div className="error-buttons-wrapper-right">
                    <button onClick={handleUselessClicks}>Life sucks</button>
                    <button onClick={handleUselessClicks}>I HATE WAITING!</button>
                </div>
            </div>
        </>
    </Layout>
  );
}

const mapStateToProps = state => ({
    showError: state.showError, // Updated mapStateToProps
  });
  
  const mapDispatchToProps = {
    setShowError, // Updated mapDispatchToProps
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Error);