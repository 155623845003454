import React, { useState, useEffect } from "react";

function Bar() {
  const sources = ['pornhub.c...', 'discord ...', 'my_ass...', 'random_por...', 'shitstagra...'];
  const [sourceIndex, setSourceIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the index to switch to the next source
      setSourceIndex((prevIndex) => (prevIndex + 1) % sources.length);
    }, 2000); // Change text every 2 seconds

    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <div className="loading">
      {/* Display the current source from sources array */}
      <span>Copying new_epic_project from {sources[sourceIndex]}</span>
      <div className="loading-bar">
        <div className="loading-inner"></div>
      </div>
      <span>Estimated time left: 6 weeks</span>
    </div>
  );
}

export default Bar;