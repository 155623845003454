import React from 'react';
import './notifications.css';
import PropTypes from 'prop-types';
import { setShowNotification } from '../../redux/actions/actions';
import { connect } from 'react-redux';

function Layout({ children, showNotification }) {

  return (
    <div className="notification">
      {showNotification === 0 ? (
        <div className="drag-container-notifications">
          <div className="content-wrap-notifications">
            {React.Children.map(children, child =>
              React.cloneElement(child)
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  setShowNotification: PropTypes.func.isRequired,
  showNotification: PropTypes.number.isRequired, // Updated prop type to match reducer
  closeWindow: PropTypes.func.isRequired, // Prop type for closeWindow function
};

const mapStateToProps = state => ({
  showNotification: state.showNotification,
});

const mapDispatchToProps = {
  setShowNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
