const initialState = {
  closed: false,
  showError: [],
  numbers: [],
  showNotification: 0, // Set default value to 0
  // other initial state properties
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CLOSED':
      return {
        ...state,
        closed: action.payload,
      };
      case 'SET_SHOW_ERROR':
        return {
          ...state,
          showError: [...state.showError, action.payload], // Append the new value to showError array
        };
    case 'ADD_NUMBER':
      return {
        ...state,
        numbers: [...state.numbers, action.payload],
      };
    case 'SET_SHOW_NOTIFICATION':
      return {
        ...state,
        showNotification: action.payload,
      };
    // Set default value to 0 for action type 'SET_SHOW_NOTIFICATION'
    case 'SET_SHOW_NOTIFICATION_DEFAULT':
      return {
        ...state,
        showNotification: 0,
      };
    // other cases
    default:
      return state;
  }
};

export default reducer;
