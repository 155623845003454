import React from 'react';
import './error.css';
import Draggable from 'react-draggable';
import { setShowError } from '../../redux/actions/actions'; // Updated import statement
import { connect } from 'react-redux';

function Layout({ children, setShowError, showError }) { // Updated function argument

  const closeWindow = () => {
    setShowError(showError => showError + 1); // Call setShowError action creator
    console.log('works only this', showError);
  };

  return (
    <div className="error">
      {showError.map((error, index) => (
        <Draggable
        key={index}
        handle=".handle"
        defaultPosition={{ x: error.x, y: error.y }}
        position={null}
        scale={1}
      >
        <div className="drag-container-error">
          <div className="handle">
            <span className="window-title">my_life.exe Internets shit overload</span>
          </div>
          <div className="content-wrap-error">
          {React.Children.map(children, child =>
              React.cloneElement(child, { closeWindow })
            )}
          </div>
        </div>
      </Draggable>
      ))}
    </div>
  );
}

const mapStateToProps = state => ({
  showError: state.showError, // Updated mapStateToProps
});

const mapDispatchToProps = {
  setShowError, // Updated mapDispatchToProps
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
