
import React from "react";
import loading from '../../images/tshell32_170.gif'

function Animation() {
  return (
    <>
       <img src={loading} alt="loading..." />
    </>
  );
}

export default Animation;