import './App.css';
import Draggable from 'react-draggable';
import close from './images/close.svg'
import minimize from './images/minimize.svg'
import Icons from './components/icons/Icons';
import Product from './components/product/Product';
import Bin from './components/bin/Bin';
import Coming from './components/coming/coming';
import Error from './components/error/error';
import Notifications from './components/notifications/notifications';

function App() {

  return (
    <div className="app">
      <Icons />
      {/* <Product />
      <Bin /> */}
      <Coming />
      <Error />
      <Notifications />
    </div>
  );
}

export default App;
