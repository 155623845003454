import React, { useState } from 'react';
import './product.css';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import close from '../../images/close.svg';
import minimize from '../../images/minimize.svg';
import { setClosed } from '../../redux/actions/actions';
import { connect } from 'react-redux';

function Layout({ children, setClosed, closed }) {

  const closeWindow = () => {
    setClosed(true);
    console.log('works only this');
  };

  return (
    <div className="product">
      {!closed ? (
        <Draggable
          handle=".handle"
          defaultPosition={{ x: 200, y: 100 }}
          position={null}
          scale={1}
        >
          <div className="drag-container">
            <div className="handle">
              <span className="window-title">wtf.exe</span>
              <div className="buttons-wrap">
                <button onClick={() => closeWindow()} className="button-close">
                  <img width={14} height={14} src={minimize} alt="minimize" />
                </button>
                <button onClick={() => closeWindow()} className="button-close">
                  <img width={14} height={14} src={close} alt="close" />
                </button>
              </div>
            </div>
            <div className="content-wrap">
            {React.Children.map(children, child =>
                React.cloneElement(child, { closeWindow })
              )}
            </div>
          </div>
        </Draggable>
      ) : null}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  setClosed: PropTypes.func.isRequired,
  closed: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  closed: state.closed,
});

const mapDispatchToProps = {
  setClosed,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
