import React, { useState, useEffect } from 'react';
import Layout from "./Layout";
import { setShowNotification } from '../../redux/actions/actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const info = <svg width="40" height="40" viewBox="0 0 163 162" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_0_1" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="4" y="4" width="155" height="154">
<path d="M4.68274 80.8886C4.68274 38.4886 39.0588 4.11526 81.4627 4.11526C123.867 4.11526 158.241 38.4886 158.241 80.8886C158.241 123.302 123.867 157.675 81.4627 157.675C39.0588 157.675 4.68274 123.302 4.68274 80.8886Z" fill="white"/>
</mask>
<g mask="url(#mask0_0_1)">
<path d="M4.68274 80.8886C4.68274 38.4886 39.0588 4.11526 81.4627 4.11526C123.867 4.11526 158.241 38.4886 158.241 80.8886C158.241 123.302 123.867 157.675 81.4627 157.675C39.0588 157.675 4.68274 123.302 4.68274 80.8886Z" fill="url(#paint0_linear_0_1)"/>
</g>
<path d="M81.4627 7.98198C41.2601 7.98198 8.54804 40.6887 8.54804 80.8887C8.54804 121.102 41.2601 153.809 81.4627 153.809C121.665 153.809 154.377 121.102 154.377 80.8887C154.377 40.6887 121.665 7.98198 81.4627 7.98198ZM81.4627 161.542C36.9921 161.542 0.81604 125.369 0.81604 80.8887C0.81604 36.422 36.9921 0.248642 81.4627 0.248642C125.933 0.248642 162.109 36.422 162.109 80.8887C162.109 125.369 125.933 161.542 81.4627 161.542Z" fill="#512A07"/>
<path d="M95.5986 33.1153V60.5153H67.6V33.1153H95.5986ZM93.824 69.9553L95.7373 128.675H67.6986L68.9586 69.9553H93.824Z" fill="#512A07"/>
<defs>
<linearGradient id="paint0_linear_0_1" x1="27.1707" y1="135.182" x2="135.753" y2="26.5997" gradientUnits="userSpaceOnUse">
<stop stop-color="#F4CECB"/>
<stop offset="0.229108" stop-color="#F4CECB"/>
<stop offset="1" stop-color="#F294FF"/>
</linearGradient>
</defs>
</svg>

function Notifications({setShowNotification}) {
  const [count, setCount] = useState(5871);

  const closeNotification = () => {
    setShowNotification(1)
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(prevCount => prevCount + 1);
    }, Math.floor(Math.random() * (5000 - 1000)) + 1000); // Random interval between 1 to 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <Layout>
      <>
        <div className="notification-top-wrapper">
          {info}
          <div>
            <span>{count} new emails</span>
            <p>and counting</p>
          </div>
        </div>

        <div className="notification-buttons-wrapper">
          <button onClick={closeNotification} className="button-notification">OMG</button>
        </div>
      </>
    </Layout>
  );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    setShowNotification: PropTypes.func.isRequired,
    showNotification: PropTypes.number.isRequired, // Updated prop type to match reducer
    closeWindow: PropTypes.func.isRequired, // Prop type for closeWindow function
  };

const mapStateToProps = state => ({
    showNotification: state.showNotification,
  });
  
  const mapDispatchToProps = {
    setShowNotification,
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
