
import React from "react";

function Buttons() {
  return (
    <div className="loading-bottons-wrap">
       <button className="open-button" disabled>Open</button>
       <button>Don't care</button>
    </div>
  );
}

export default Buttons;